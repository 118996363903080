/*
 -  Main scripts
 */

import $ from 'jquery';                            // Import jQuery lib
import 'bootstrap/js/dist/util';                   // Import bootstrap plugins
import 'bootstrap/js/dist/carousel';


// Document ready
$(function() {
	var $window = $( window ),
			$header = $( '.header' ),
			$teaser = $( '.section-teaser' ),
			$sourceMedium = $( '.source-medium' ),
			$scrollToTop = $( '.scroll-to-top' ),
			classToggle = 'header--mobile-nav-opened',
			$video1 = $( '#drag-and-drop-video' )[0],
			$video2 = $( '#test-it-in-a-minute-video' )[0];

	/* Set current year date to footer */

	$("#footer-year").text( new Date().getFullYear() );


	/* Video autoplay */

	$video1.muted = true;
	$video1.playsInline = true;
	$video1.loap = true;
	$video1.controls = true;
	$video1.play();

	$video2.loap = true;
	$video1.playsInline = true;
	$video2.muted = true;
	$video2.controls = true;
	$video2.play();


	/* Carousels */

	$('#teaser-carousel').carousel({
		interval: 6000,
		pause: 'hover'
	});

	$( $sourceMedium[Math.floor(Math.random() * 2)] ).removeClass( 'source-medium--random' );


	/* Timers */

	setTimeout(function(){
		sameHeight($( '.carousel--teaser .carousel-item' ));
	},50);

	setTimeout(function(){
		$sourceMedium.removeClass( 'source-medium--on-start' );

		setTimeout(function(){
			$( '.section-teaser-in' ).removeClass( 'section-teaser-in--on-start' );
		}, 500);
	}, 1000);

	/* Window events */

	$window.on('scroll', function() {
		var scrollPosition = $window.scrollTop();

		if ( scrollPosition > 1 ) {
			$header.addClass( 'header--scrolled' );
		} else {
			$header.removeClass( 'header--scrolled' );
		}

		if ( scrollPosition > ( $teaser.outerHeight() * .25 ) ) {
			$scrollToTop.addClass( 'scroll-to-top--showed' );
		} else {
			$scrollToTop.removeClass( 'scroll-to-top--showed' );
		}

		if ( scrollPosition > ( $teaser.outerHeight() * .7 ) ) {
			$sourceMedium.addClass( 'source-medium--on-start' );
		} else {
			$sourceMedium.removeClass( 'source-medium--on-start' );
		}
	});

	$window.on('resize', function(){
		// reset
		$( '.carousel--teaser .carousel-item').attr('style', '');

		sameHeight($( '.carousel--teaser .carousel-item' ));
	});



	$('.main-nav a, .scroll-to-top').on('click', function(e) {
		if (this.hash !== "") {
			e.preventDefault();

			var $this = $( this ),
					hash = this.hash,
					manualOffsetValue = $( this ).data('scroll-offset');

			scrollTo(hash, manualOffsetValue, function(){
				$this.trigger('blur');
			});
		}
	});

	$( '.main-nav a, .nav-toggle' ).on('click', function(e) {
		$header.toggleClass( classToggle );
	});



	/* Functions */

	/* Set same height to boxes */

	function sameHeight($el) {
		var selector = $el;
		var heights = [];

		selector.each(function(){
				var height = $(this).height();
				heights.push(height);
		});

		var maxHeight = Math.max.apply(null, heights);

		selector.each(function(){
				$(this).height(maxHeight);
		});
	}



	/* Smooth scroll to */

	function scrollTo(target, offset, callback) {
		var
			target = target ? target : '',
			offset = offset ? offset : 0,
			callback = callback ? callback : null;

		$('html, body').animate({
			scrollTop: $(target).offset().top + offset
		}, 800, function(){
			callback();
		});
	}
});